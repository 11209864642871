<template>
    <el-link :href="linkUrl" target="_blank" type="primary">{{ linkLable }}</el-link>
</template>

<script>
export default {
    props: {
        linkLable: {
            type: String,
            default: '描述',
        },
        linkUrl: {
            type: String,
            default: 'http://123.com',
        },
    },
}
</script>

<style lang="scss" scoped>

</style>